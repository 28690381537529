<template>
	<div class="disDetails">
		<div style="width: 100%;height: 40px;"></div>
		<div class="container">
			<div class="top-details">
				<h5>{{goodsDetail.name}}</h5>
				<!-- <p class="bankname">建设银行</p> -->
				<div class="today-SY">
					<div class="progress">
						<span class="progressing" v-bind:style=" 'width:' + (goodsDetail.stock/goodsDetail.count)*100 +'%' ">
						</span>
					</div>
					<p>今日剩余
						<span style="color: red;">{{parseInt(goodsDetail.stock/goodsDetail.count*100)}}</span>%
					</p>
				</div>
				<p class="atOnce" @click="onGet(goodsDetail.id)" v-if="goodsDetail.stock != 0">立即领取</p>
				<p class="finish" v-else>已领完</p>
				<p class="hint">适用于：{{goodsDetail.shop_name}}</p>
			</div>
			<div class="bot-details">
				<div class="listDeta">
					<div>
						<img src="../../assets/time.png" alt="" />
						<p>{{timestampToTime(goodsDetail.start_time)}}-{{timestampToTime(goodsDetail.end_time)}}</p>
					</div>
					<!-- <div>
						<img src="../../assets/bank.png" alt="" />
						<p>建设银行银联卡</p>
					</div> -->
					<!-- <div>
						<img src="../../assets/active.png" alt="" />
						<p>详见活动规则</p>
					</div> -->
					<div>
						<img src="../../assets/guize.png" alt="" />
						<p>详见使用细则</p>
					</div>
				</div>
				<div class="img-detail">
					<!-- <img src="https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=2832316507,902801110&fm=26&gp=0.jpg"
						alt="" class="goods-image"> -->
					<img v-if="goodsDetail.image" :src="imageUrl+goodsDetail.image" alt="" class="goods-image">
				</div>
				<!-- <van-cell-group>
					<van-cell title="适用于" is-link style="position: relative;" />
					<div style="position: absolute;right: 40px;top: 0;display: flex;align-items: center;">
						<p
							style="font-size: 12px;color: #888;width: 80px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;">
							绝味鸭脖(郑州新田360国贸)</p>
						<p style="font-size: 12px;color: #888;">660m</p>
					</div>
				</van-cell-group> -->
				<!-- <van-cell is-link title="活动资询" @click="show = true" /> -->
				<van-action-sheet v-model:show="show" title="客服">
					<div class="content">
						<div style="display: flex;align-items: center;">
							<p style="font-size: 14px;font-weight: 600;">票券编码</p>
							<p style="font-size: 13px; color: #666;margin-left: 10px;">1 2012 0201 2161 820</p>
						</div>
						<div style="display: flex;align-items: center;justify-content: space-between;">
							<p style="font-size: 14px;font-weight: 600;">在线客服</p>
							<img src="../../assets/right.png" alt="" style="width: 18px;height: 18px;">
						</div>
						<div style="display: flex;align-items: center;justify-content: space-between;">
							<p style="font-size: 14px;font-weight: 600;">客服电话<span style="color: #999;font-weight: normal;margin-left: 10px;font-size: 13px;">95516</span>
							</p>
							<img src="../../assets/right.png" alt="" style="width: 18px;height: 18px;">
						</div>
					</div>
				</van-action-sheet>
			</div>
		</div>
		<div class="sygz">
			<van-collapse v-model="activeName">
				<van-collapse-item title="使用细则" name="1">
					{{goodsDetail.detail}}
				</van-collapse-item>
			</van-collapse>
		</div>
	</div>
</template>

<script>
	import {
		Toast
	} from 'vant';
	import {
		ref
	} from 'vue';
	import {
		discountDetails,
		getDiscount
	} from '@/api/user'
	export default {
		data() {
			return {
				goodsDetail: '',
				imageUrl:this.url.imageUrl
			}
		},
		setup() {
			const show = ref(false);
			const activeName = ref(['1']);
			return {
				show,
				activeName
			};
		},
		methods: {
			discountDetails() {
				this.$toast.loading({
					message: '加载中...',
					forbidClick: true,
				});
				let params = {}
				params.id = this.$route.query.id
				discountDetails(params).then((res) => {
					console.log(res)
					this.goodsDetail = res.data;
					this.$toast.clear()
				})
			},
			onGet(id){
				this.$toast.loading({
					message: '加载中...',
					forbidClick: true,
				});
				let params = {}
				params.id = id
				getDiscount(params).then((res) => {
					// console.log(res)
					Toast.success(res.msg);
					this.$toast.clear()
				})
			},
			timestampToTime(timestamp) {
				var date = new Date(timestamp * 1000);
				let y = date.getFullYear();
				let MM = date.getMonth() + 1;
				MM = MM < 10 ? ('0' + MM) : MM;
				let d = date.getDate();
				d = d < 10 ? ('0' + d) : d;
				let h = date.getHours();
				h = h < 10 ? ('0' + h) : h;
				let m = date.getMinutes();
				m = m < 10 ? ('0' + m) : m;
				let s = date.getSeconds();
				s = s < 10 ? ('0' + s) : s;
				return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
			}
		},
		mounted() {
			this.discountDetails()
		}
	}
</script>

<style lang="less" scoped>
	@import "~@/assets/style/preset.less";

	.disDetails {
		width: 100%;
		height: 250px;
		background: linear-gradient(red, #fff);

		.container {
			width: 85%;
			height: 355px;
			background-color: #FFFFFF;
			box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.15);
			border-radius: 10px;
			margin: 0 auto;
			position: relative;

			.top-details {
				width: 100%;
				height: 40%;
				border-bottom: dotted 1px #999;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				h5 {
					margin: 0;
				}

				.bankname {
					font-size: 13px;
				}

				.today-SY {
					display: flex;
					align-items: center;

					.process {
						width: 90px;
						height: 3px;
						margin: 5px;
						background-color: #ffd100;

						div {
							height: 3px;
							background: #d4ad00;
						}
					}

					p {
						margin: 8px 0 8px 5px;
						font-size: 12px;
					}
				}

				.atOnce {
					font-size: 12px;
					border: solid 1px red;
					color: red;
					padding: 5px 8px;
					margin: 0;
					border-radius: 5px;
				}
                 .finish{
					 font-size: 12px;
					 border: solid 1px #a1a1a1;
					 color: #a1a1a1;
					 padding: 5px 8px;
					 margin: 0;
					 border-radius: 5px;
				 }
				.hint {
					font-size: 12px;
				}
			}

			.bot-details {
				width: 100%;
				height: 70%;

				.listDeta {
					margin-left: 15px;
					margin-top: 15px;

					div {
						display: flex;
						align-items: center;
						padding: 3px 0;

						img {
							width: 18px;
							height: 18px;
						}

						p {
							font-size: 12px;
							margin: 0;
							margin-left: 10px;
							color: #666;
						}
					}
				}

				.img-detail {
					width: 100px;
					height: 100px;
					margin: 5px auto;

					img {
						width: 100%;
						height: 100%;
					}
				}

			}
		}

		.sygz {
			width: 85%;
			// height: 100px;
			background-color: #FFFFFF;
			box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.15);
			border-radius: 10px;
			margin: 15px auto;
		}
	}

	.content {
		padding: 16px 16px 80px;
	}
	.progress {
		width: 60px;
		height: 3px;
		background: #ac0000;
		// border: 1px solid #ecc353;
		border-radius: 10px;
		text-align: center;
		color: #fff;
		margin: 3px 0;
		.progressing {
			position: relative;
			float: left;
			margin: 0 auto;
			height: 3px;
			background: red;
			border-radius: 10px;
	
			.percentage {
				margin-left: 50px;
			}
		}
	}
</style>
